/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-vars */
//teste
import React, { useState, useEffect  } from 'react';
import sendChanges from '../services/sendChanges';
import { obterDataFormatada } from '../services/todayDate';
import { formatDate } from '../services/dateHandle';
import { convertTimestampToET } from '../services/timezones';
import { eventcodesName } from '../services/eventcodes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faAnglesRight, faAnglesLeft, faTimes} from '@fortawesome/free-solid-svg-icons';
import {getDriverName} from '../services/driverName'
import { companyName } from '../services/companyName';
import { checkDot } from '../services/checkDot';
import { backUp15Days } from '../services/backUp15Days';
import { backUpToday } from '../services/backUpToday';
import Sidebar from './sideBar';
import { fi } from 'date-fns/locale';
import { untilToday } from '../services/untilToday';
// import saveDbInfo from '../services/sequelize'
localStorage.setItem('hosEventsLog', '[]')





function Table() {

   
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const hoseventsArray = [];
  let i =0;
  const handleClick2 = async () => {
  await handleClick()
  backUp15Days();
  checkDot();
  }
  const handleClick = async () => {
    
    companyName(localStorage.getItem('token'), localStorage.getItem('companyId'));
    getDriverName(localStorage.getItem('token'), localStorage.getItem('userId'));
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    i=0;
    hoseventsArray.length = 0;;
    setIsLoading(true);
    setError(null);
    
    const requestOptions = {
      method: 'GET',
      headers: { 
        "accept": "application/json",
        "accept-language": "pt-BR,pt;q=0.9,en-US;q=0.8,en;q=0.7",
        "authorization": localStorage.getItem('token'),
        "content-type": "application/json",
        "if-none-match": "W/\"1186f-3c2PqL1eocqag01MrbGuekXTmK8\"",
        "sec-ch-ua": "\"Not_A Brand\";v=\"99\", \"Google Chrome\";v=\"109\", \"Chromium\";v=\"109\"",
        "sec-ch-ua-mobile": "?0",
        "sec-ch-ua-platform": "\"Windows\"",
        "sec-fetch-dest": "empty",
        "sec-fetch-mode": "cors",
        "sec-fetch-site": "cross-site" 
      }
    };
    
    const firstDate = localStorage.getItem('firstDateUrl');
    const lastDate = localStorage.getItem('lastDateUrl');
    const userId = localStorage.getItem('userId');
    const url = `https://backend.apexhos.com/hos_events?userId=${userId}&eventTime.logDate.date%5B%24lte%5D=${lastDate}&eventTime.logDate.date%5B%24gte%5D=${firstDate}`;
    console.log(url);
    
    try {
      const response = await fetch(url, requestOptions);
      const result = await response.json();
      setData(result.data);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };


  const updateHour = async () =>{
    if (document.getElementById("timeCondition").value === 'notSelected') {alert("Select One option to move the events")}
    else{
      
    // eslint-disable-next-line no-restricted-globals
      
      const confirmed = confirm(`Are you sure you want to update hours? ${document.getElementById("timeCondition").value} ${document.getElementById("hoursToChange").value} Hour `); // Confirmation alert
          let notFuture = true;
    localStorage.setItem('hosEventsLog',  JSON.stringify(data))
    // saveDbInfo(JSON.stringify(data));
    data.map((hosEventElement) => {
      if (document.getElementById("timeCondition").value === 'foward') {
        const now = new Date().getTime();
        const time = hosEventElement.eventTime.timestamp;
        const hoursToms = document.getElementById("hoursToChange").value * 3600;
        const newTime = time / 1000;
        const newTimeStamp = (newTime + hoursToms) * 1000;
        console.log('agora' , now);
        console.log('mudado ' , newTimeStamp);
        console.log(newTimeStamp > now);
        
        if (newTimeStamp > now && false) {
          console.log('not allowed time travel yet');
          alert(`schedule event ${hosEventElement.id} is not allowed`)
          notFuture = false;
          return "";
        }
      }
      return "";
    });
    if (confirmed && notFuture) {
      await Promise.all(data.map(async (hosEventElement) => {
      const hosEvent = hosEventElement._id;
      const time = hosEventElement.eventTime.timestamp;
      console.log(hosEvent);
      if (document.getElementById(hosEventElement._id) !== null) {
        console.log('tem');
        const checkbox = document.getElementById(hosEventElement._id);
        console.log(checkbox.checked);
        const isChecked = checkbox.checked;
        if (isChecked) {
          console.log(`achei ${checkbox}`);
          console.log(hosEventElement);
          if (document.getElementById("timeCondition").value === 'back') {
            const hoursToms = document.getElementById("hoursToChange").value * 3600;
            const newTime = time / 1000;
            const newTimeStamp = (newTime - hoursToms) * 1000;
            hosEventElement.eventTime.timestamp = newTimeStamp;


          }
          if (document.getElementById("timeCondition").value === 'foward') {
            const hoursToms = document.getElementById("hoursToChange").value * 3600;
            const newTime = time / 1000;
            const newTimeStamp = (newTime + hoursToms) * 1000;
            hosEventElement.eventTime.timestamp = newTimeStamp;
          }

          const dateFormated = convertTimestampToET(hosEventElement.eventTime.timestamp, localStorage.getItem('timezone') );
        let dateObj = new Date(dateFormated); // convert date string to a Date object

        let year = dateObj.getFullYear();
        let month = String(dateObj.getMonth() + 1).padStart(2, '0');
        let day = String(dateObj.getDate()).padStart(2, '0');
        let transformedDate = `${year}/${month}/${day}`;
        hosEventElement.eventTime.logDate.date = transformedDate;
        console.log(`before change ${time} : ${transformedDate} after change ${hosEventElement.eventTime.timestamp}`);
        console.log(hosEventElement);
          await sendChanges(hosEvent, hosEventElement, 'PUT');
          console.log(hosEventElement);
        }
      }

    }))
    
    setTimeout(() => {
      handleClick()
      document.getElementById('allCheckBox').checked = false;
    }, 1500)}
    else{
      console.log('no confirmed');
    }
      
  }            
  }



  const dotSet = async () =>{
      
    // eslint-disable-next-line no-restricted-globals
    const confirmed = confirm(`Are you sure you want to block this event?`); // Confirmation alert
    localStorage.setItem('hosEventsLog',  JSON.stringify(data))
    // saveDbInfo(JSON.stringify(data));
    if (confirmed) {
      await Promise.all(data.map(async (hosEventElement) => {
      const hosEvent = hosEventElement._id;
      if (document.getElementById(hosEventElement._id) !== null) {
        console.log('tem');
        const checkbox = document.getElementById(hosEventElement._id);
        console.log(checkbox.checked);
        const isChecked = checkbox.checked;
        if (isChecked) {
          hosEventElement.i = true;
          await sendChanges(hosEvent, hosEventElement, 'PUT');
          console.log(hosEventElement);
        }
      }

    }))
    
    setTimeout(() => {
      handleClick()
      document.getElementById('allCheckBox').checked = false;
    }, 1500)}
    else{
      console.log('no confirmed');
    }
      
  }   
  
  



  const unSet = async () =>{
      
    // eslint-disable-next-line no-restricted-globals
    const confirmed = confirm(`Are you sure you want to unblock this event?`); // Confirmation alert
    localStorage.setItem('hosEventsLog',  JSON.stringify(data))
    // saveDbInfo(JSON.stringify(data));
    if (confirmed) {
      await Promise.all(data.map(async (hosEventElement) => {
      const hosEvent = hosEventElement._id;
      console.log(hosEventElement.i);
      if ( hosEventElement.i === true) {
        console.log('tem');
        hosEventElement.i = false;
          console.log(`achei ${hosEventElement}`);
          console.log(hosEventElement);
          delete hosEventElement.i;
          await sendChanges(hosEvent, hosEventElement, 'DELETE');
          await sendChanges(hosEvent, hosEventElement, 'PUT');
          console.log(hosEventElement);

      }

    }))
    
    setTimeout(() => {
      handleClick()
      document.getElementById('allCheckBox').checked = false;
    }, 1500)}
    else{
      console.log('no confirmed');
    }
      
  }  







  const restoreback = async (data) =>{
    
    data.data.map((hosEventElement)=> {
          const hosEvent = hosEventElement._id;
          sendChanges(hosEvent, hosEventElement, 'PUT');
          console.log(hosEventElement);
          return hosEventElement;
        })
        
  }




  const [token, setToken] = useState(localStorage.getItem('token') || '');
  const [url, setUrl] = useState(localStorage.getItem('url') || '');
  const [user, setUser] = useState(localStorage.getItem('user') || '');
  const [date, setDate] = useState(localStorage.getItem('date') || '');
  const [timezone, setTimezone] = useState(localStorage.getItem('timezone') || '');
  const [isCopied, setIsCopied] = useState(false);


  const handleTokenChange = (event) => {
    const newToken = event.target.value;
    document.getElementById('timeCondition').selectedIndex = 0
    setToken(newToken);
    localStorage.setItem('token', newToken);
    document.getElementById('urlInput').value = '';
    setUrl('');
    localStorage.setItem('url', '');
  };


  const  goToToday =() => {
  const todayDate = obterDataFormatada();
  const todayDateUrl = encodeURIComponent(todayDate);
  document.getElementById('urlInput').value = `https://portal.undercontroltechnology.com/portal/log/${localStorage.getItem('userId')}/${todayDateUrl}/0/${localStorage.getItem('timezone')}`;
  handleUrlChange();
  handleClick();
  
  
}


  const handleUrlChange = () => {

    const newUrl = document.getElementById('urlInput').value;
    setUrl(newUrl);
    localStorage.setItem('url', newUrl);
    const ulrArray = newUrl.split('/');
    const date = ulrArray[6].replaceAll('-', '/');
    const dateArray = date.split('/');
    let month;
    if (dateArray[0].length < 2) {
      month = '0' + dateArray[0];
    }
    else{
       month = dateArray[0];

    }
    let day;
    if (dateArray[1].length < 2) {
      day = '0' + dateArray[1];
    }
    else{
       day = dateArray[1];

    }

    const year =dateArray[2];

    setUser(ulrArray[5]);
    setDate(date);
    setTimezone(ulrArray[8]);
    const urlDate = `${year}/${month}/${day}`
    localStorage.setItem('firstDate',urlDate)
    localStorage.setItem('lastDate',urlDate)
    localStorage.setItem('firstDateUrl',encodeURIComponent(urlDate))
    localStorage.setItem('lastDateUrl',encodeURIComponent(urlDate))
    localStorage.setItem('userId',ulrArray[5])
    localStorage.setItem('timezone',ulrArray[8])
  };


  const [checkAll, setCheckAll] = useState(false);
  const [checkboxValues, setCheckboxValues] = useState([
    false,
    false,
    false,
    false
  ]);

  function handleCheckAll() {
    hoseventsArray.map((hosevent) => {
      if (document.getElementById(hosevent) !== null) {  
        console.log(hosevent);
        document.getElementById(hosevent).checked = document.getElementById('allCheckBox').checked
        return 'finish'
      }
      return "";
    });
  }

  const changeDateUp = () =>{
const dateStr = localStorage.getItem('lastDate');
const date = new Date(dateStr);
const timestamp = date.getTime();
const newDate = (timestamp / 1000) + 86400;
const dateFormatted = new Date(newDate * 1000);
const dateString = `${dateFormatted.getMonth() + 1}-${dateFormatted.getDate()}-${dateFormatted.getFullYear()}`;
document.getElementById('urlInput').value = `https://portal.undercontroltechnology.com/portal/log/${localStorage.getItem('userId')}/${dateString}/0/${localStorage.getItem('timezone')}`
setTimeout(() => {
  handleUrlChange()
   handleClick()
  document.getElementById('allCheckBox').checked = false;
}, 500)
}
const changeDateDown = () =>{
  const dateStr = localStorage.getItem('lastDate');
  const date = new Date(dateStr);
  const timestamp = date.getTime();
  const newDate = (timestamp / 1000) - 86400;
  const dateFormatted = new Date(newDate * 1000);
  const dateString = `${dateFormatted.getMonth() + 1}-${dateFormatted.getDate()}-${dateFormatted.getFullYear()}`;
  document.getElementById('urlInput').value = `https://portal.undercontroltechnology.com/portal/log/${localStorage.getItem('userId')}/${dateString}/0/${localStorage.getItem('timezone')}`
  setTimeout(() => {
    handleUrlChange()

    handleClick()
    document.getElementById('allCheckBox').checked = false;
  }, 500)

}
const changeDateDownAndUpdate = async() =>{
  var checkboxes = document.querySelectorAll('input[type=checkbox]');
    for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = true;
    }
    setTimeout(async function() {
      await updateHour();
      changeDateDown();
  }, 500);
  

}
const changeDateUpAndUpdate = async () =>{
  var checkboxes = document.querySelectorAll('input[type=checkbox]');
    for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = true;
    }
    setTimeout(async function() {
      await updateHour();
      
      changeDateUp();
  }, 500);

}





const handleCheckbox = (id) =>{
  if (id === 'none') {
    return 'false'
  }
  console.log(document.getElementById(id).checked);
  console.log(document.getElementById(id).disabled);

  document.getElementById(id).checked = !document.getElementById(id).checked
}
 

const undoFunc = async () => {
  const confirmAction = confirm("Are you sure you want to undo these changes? This action cannot be undone.");
  
  if (confirmAction) {
    const obj = JSON.parse(localStorage.getItem("hosEventsLog"));
    await Promise.all(obj.map(async (hosEventElement) => {
      const hosEvent = hosEventElement._id;
      console.log(`UNDO ${hosEvent}`);
      await sendChanges(hosEvent, hosEventElement, 'PUT');
    }));
    setTimeout(() => {
      handleClick();
      document.getElementById("allCheckBox").checked = false;
    }, 1500);
  }
};

const deleteFunc = async () => {

  const confirmAction = confirm("Are you sure you want to delete these events? This action cannot be undone.");
  if (confirmAction) {
    let statusConfirmed = false;
    await Promise.all(data.map(async (hosEventElement) => {
      const hosEvent = hosEventElement._id;
      console.log(hosEvent);
      if (document.getElementById(hosEventElement._id) !== null) {
        const checkbox = document.getElementById(hosEventElement._id);
        const isChecked = checkbox.checked;
        if (isChecked) {
          console.log(`achei ${checkbox}`);
          console.log(hosEventElement);
          console.log(hosEventElement.eventCode.id);
          if (!statusConfirmed) {
            let status1 = '';
        hosEventElement.hasOwnProperty('recordStatus') ?  status1 = hosEventElement.recordStatus.id :  status1 = '';
            if (status1 === "ACTIVE"  && (hosEventElement.eventCode.id === 'DS_SB'||hosEventElement.eventCode.id === 'DS_ON'||hosEventElement.eventCode.id === 'DS_D' ||hosEventElement.eventCode.id === 'DR_IND_PC' ||hosEventElement.eventCode.id === 'DS_OFF' || hosEventElement.eventCode.id === 'LOG_NORMAL_PRECISION')) {
              const driverStatusAction = confirm("Are you really sure you want to delete these events? . There some DRIVE events in this selection.");
              if (driverStatusAction) {
                
                await sendChanges(hosEvent, hosEventElement, 'DELETE');
              }
              else{
                console.log('not confirmed');
              }
            }
            
            else{ 
              await sendChanges(hosEvent, hosEventElement, 'DELETE');
            }
          }
          
        }
      }

    }))
    setTimeout(() => {
      handleClick()
      document.getElementById('allCheckBox').checked = false;
    }
    , 1500)
  }
}




const clearLog = async () => {
  // Ordena os dados pelo timestamp em ordem decrescente
  data.sort((a, b) => b.eventTime.timestamp - a.eventTime.timestamp);

  var checkboxes = document.querySelectorAll('input[type=checkbox]');
  for (var i = 0; i < checkboxes.length; i++) {
      checkboxes[i].checked = false;
  }

  const interval = 100;
  const delay = 5000; // 5 segundos

  for (let i = 0; i < data.length; i += interval) {
      for (let j = i; j < i + interval && j < data.length; j++) {
          const hosEventElement = data[j];
          if (document.getElementById(hosEventElement._id) !== null) {
              const checkbox = document.getElementById(hosEventElement._id);
              let status1 = '';
              hosEventElement.hasOwnProperty('recordStatus') ? status1 = hosEventElement.recordStatus.id : status1 = '';
              if (status1 !== "ACTIVE" || (hosEventElement.eventCode.id !== 'DS_SB' && hosEventElement.eventCode.id !== 'DS_ON' && hosEventElement.eventCode.id !== 'DS_D' && hosEventElement.eventCode.id !== 'DR_IND_PC' && hosEventElement.eventCode.id !== 'DS_OFF'&& hosEventElement.eventCode.id !== 'DR_CERT'&& hosEventElement.eventCode.id !== 'DR_IND_YM'&& hosEventElement.eventCode.id !== 'LOG_NORMAL_PRECISION' && hosEventElement.eventCode.id !== 'DR_IND_YM')) {
                  checkbox.checked = true;
              }
          }
      }

      // Aguarda 5 segundos antes de continuar para o próximo lote
      await new Promise(resolve => setTimeout(resolve, delay));
  }

  // Aguarda 1.5 segundos antes de chamar handleClick e desmarcar o checkbox 'allCheckBox'
  await new Promise(resolve => setTimeout(resolve, 1500));
  handleClick();
  document.getElementById('allCheckBox').checked = false;
}


const changeOnToOff = async () => {
  // const confirmAction = confirm("Are you sure you want to delete these events? This action cannot be undone.");

    await Promise.all(data.map(async (hosEventElement) => {
      if (document.getElementById(hosEventElement._id) !== null) {
        const checkbox = document.getElementById(hosEventElement._id);
        console.log(hosEventElement.eventCode.id);
        let status1 = '';
        hosEventElement.hasOwnProperty('recordStatus') ?  status1 = hosEventElement.recordStatus.id :  status1 = '';
        if (hosEventElement.eventCode.id === 'DS_ON' && !hosEventElement.hasOwnProperty('eventComment')) {
          const hosEvent = hosEventElement._id;
          hosEventElement.eventCode.id = 'DS_OFF'
          // console.log(`achei ${checkbox}`);
          // console.log(hosEventElement);
          // console.log(hosEventElement.eventCode.id);
          await sendChanges(hosEvent, hosEventElement, 'PUT');
        }
      }

    }))
    setTimeout(() => {
      handleClick()
      document.getElementById('allCheckBox').checked = false;
    }
    , 1500)
}






const [isSidebarOpen, setIsSidebarOpen] = useState(false);
const [driverInfo, setDriverInfo] = useState(null);

const fetchDriverInfo = async () => {
  try {
    // Obtendo o nome do motorista do localStorage
    const driverName = localStorage.getItem('driverName');
    if (!driverName) {
      console.error('Nome do motorista não encontrado no localStorage.');
      return null;
    }

    // Fazendo o fetch da URL com o nome do motorista
    const response = await fetch(`https://api.undercontrol.tech/portal/getDriverInfor.php?driverName=${driverName}`);
    const data = await response.json();

    // Mostrar o resultado no console
    console.log('Resultado do fetch:', data);

    return data;
  } catch (error) {
    console.error('Erro ao obter informações do motorista:', error);
    return null;
  }
};

const handleToggleSidebar = async () => {
  setIsSidebarOpen(!isSidebarOpen);

  // Realizar o fetch quando o botão de abrir a barra lateral for clicado
  if (!isSidebarOpen) {
    const fetchedDriverInfo = await fetchDriverInfo();
    setDriverInfo(fetchedDriverInfo);
  }
};

const handleCloseSidebar = () => {
  setIsSidebarOpen(false);
};



function untilTodayVerify() {
  const confirmation = confirm("Are you sure you want to update hours?");
  if (confirmation) {
    untilToday();
  } else {
    
  }
}




  return (
    <div >
       <Sidebar isOpen={isSidebarOpen} onClose={handleCloseSidebar} driverInfo={driverInfo} />
     
     
      <header><h1><FontAwesomeIcon icon={faAnglesLeft} onClick={changeDateDownAndUpdate} className="iconFas"/>&nbsp;&nbsp;<FontAwesomeIcon icon={faArrowLeft} onClick={changeDateDown} className="iconFas"/> {localStorage.getItem('lastDate') === null ? `xx/xx/xxxx` : localStorage.getItem('lastDate').replace(/^(\d{4})\/(\d{2})\/(\d{2})$/, "$2/$3/$1")} <FontAwesomeIcon icon={faArrowRight}  onClick={changeDateUp} className="iconFas"/>&nbsp;&nbsp;<FontAwesomeIcon icon={faAnglesRight}  onClick={changeDateUpAndUpdate} className="iconFas"/></h1>
      <h4 id='driverName'>Not find</h4>
      <h4 id='companyName'>Not find</h4>
      </header>
      <div id='tableButtons'>

      <label>
        Token:
        <input type="text" value={token} onChange={handleTokenChange} id="tokenInput"/>
      </label>
      <label>
        URL:
        <input type="text" value={url} onChange={handleUrlChange} id="urlInput" />
      </label>
       {/* <button onClick={()=>{localStorage.clear();document.getElementById('urlInput').value = '';document.getElementById('tokenInput').value = ''; }}>Clear</button> */}
       <button onClick={handleClick2}>Fetch Data</button>
        <br />
        <br />
       <label>
        Hours to change:
       <input type="number" id='hoursToChange'/>
      </label>
      <select name="timeCondition" id="timeCondition">
      <option value="notSelected" defaultValue>Select One</option>
      <option value="foward">foward</option>
      <option value="">-</option>
      <option value="">-</option>
      <option value="">-</option>
      <option value="back">Back</option>
      </select>
       <button onClick={updateHour}>Update</button>
      <br/>
      <br/>
       <button style={{ float: 'right' }} onClick={deleteFunc}>DUMP</button>
       <button style={{ float: 'right' }} onClick={clearLog}>CLEAR</button>
       <button style={{ float: 'right' }} onClick={undoFunc}>Undo</button>
       <button style={{ float: 'right' }} onClick={handleClick}>REFRESH</button>
       <button style={{ float: 'left' }} onClick={changeOnToOff}>ON TO OFF</button>
       <button style={{ float: 'left' }} onClick={checkDot}>CHECK DOT</button>
       <button style={{ float: 'left' }} onClick={untilTodayVerify}>UP UNTIL TODAY</button>

       {/* <button style={{ float: 'left' }}  onClick={unSet}>UNSET DOT</button> */}
       <br />
       <br />
       <button style={{ float: 'left' }} onClick={backUp15Days}>BACKUP 15</button> 
       <button style={{ float: 'left' }} onClick={backUpToday}>BACKUP TODAY</button>
       <button style={{ float: 'left' }} onClick={handleToggleSidebar}>BACKUPS</button>
       <br />
       <br />
       <button style={{ float: 'left' }} onClick={dotSet}>SET DOT</button>
       <br />
      
      <div>
        
      </div>
      </div>
    <table>
      <thead>
        <tr>
          <th>DATE</th>
          <th>EVENT</th>
          <th>STATUS</th>
          <th>LOCATION</th>
          <th>MI</th>
          <th>NOTES</th>
          <th className='checkbox'><input type="checkbox" onChange={handleCheckAll} id='allCheckBox' /></th>
        </tr>
      </thead>
      <tbody>
      {data && data.length ? (
        data.filter(item => item.hasOwnProperty('eventTime'))
    .sort((a, b) => {
      const dateA = new Date(a.eventTime.timestamp);
      const dateB = new Date(b.eventTime.timestamp);
      return dateA - dateB;
    })
    .map(item => {

        const dateFormated = convertTimestampToET(item.eventTime.timestamp, localStorage.getItem('timezone') );
        let dateObj = new Date(dateFormated); // convert date string to a Date object

let year = dateObj.getFullYear();
let month = String(dateObj.getMonth() + 1).padStart(2, '0');
let day = String(dateObj.getDate()).padStart(2, '0');

let transformedDate = `${year}/${month}/${day}`;
          
        hoseventsArray.push(item._id)
          
          
          if (localStorage.getItem('firstDate') !== transformedDate ) {
            console.log(`${localStorage.getItem('firstDate')} is diferent ${transformedDate}`);
            return '';
          }
          let status1 = '';
          let eventName = eventcodesName(item.eventCode.id);
          item.hasOwnProperty('recordStatus') ?  status1 = item.recordStatus.id :  status1 = '';
          
          let disabled
          let id = item._id;
          let tdClass = '';
          let checkStatus = false;
          let why = ''
          if (item.i === true || eventName === 'Certification') {
            disabled = 'hidden'
            id = 'none'
            
            eventName = item.eventCode.id
            checkStatus = true;
            if (item.i === true) {
              tdClass = 'dot'
              why = 'DOT INSPECTION'
            }
            if (eventName === 'Certification') {
              tdClass = 'dot'
              why = 'CERTIFICATION'
            }
          }
          i++
          // let tdClass = '';
          // const hoseventLog = JSON.parse(localStorage.getItem("hosEventsLog"));
          // hoseventLog.map((hosEventElementLog) => {
          //   console.log(`${hosEventElementLog._id} = ${id}`);
          //   if (hosEventElementLog._id === id) {
          //     tdClass = 'recentChanged';
          //   }
          //   else{
          //     tdClass = 'notChanged';
          //   }
          //   return "";
          // });
        return (
          <tr key={item._id} className={tdClass}>
            <td>{item.hasOwnProperty('eventTime') ? convertTimestampToET(item.eventTime.timestamp, localStorage.getItem('timezone') ) : ''}</td>
              <td>{item.hasOwnProperty('eventCode') ?  eventName : ''}</td>
              <td>{status1}</td>
              <td>{item.hasOwnProperty('location') ? item.location.calculatedLocation : ''}</td>
              <td>{item.hasOwnProperty('totalVehicleMiles') ? item.totalVehicleMiles : ''}</td>
              <td>{item.hasOwnProperty('eventComment') ? item.eventComment : ''}</td>
              <td className='checkbox'  onClick={() => {handleCheckbox(id)}}><input type="checkbox"  id={id} disabled={checkStatus} />{why}</td>
          </tr>
        );
        
    })
) : (
  <tr>
    <td colSpan="5" id='error'>CHECK THE JWT TOKEN OR THE URL</td>
  </tr>
)}
      </tbody>
    </table>
    </div>
  );
}

export default Table;