import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns'; // Importar a função format de date-fns
import "../styles/styleSidebar.css"
import sendChanges from '../services/sendChanges';

const Sidebar = ({ isOpen, onClose, driverInfo }) => {
  const restoreback = async (data) => {
    console.log(data);
  
    try {
      const dataArray = JSON.parse(data);
  
      if (Array.isArray(dataArray)) {
        for (let i = 0; i < dataArray.length; i++) {
          const hosEventElement = dataArray[i];
          const hosEvent = hosEventElement._id;
  
          console.log(hosEvent);
  
          sendChanges(hosEvent, hosEventElement, 'PUT');
          console.log(hosEventElement);
  
          // Adicionar um atraso de 4 segundos a cada 25 rodadas
          if ((i + 1) % 75 === 0) {
            await new Promise(resolve => setTimeout(resolve, 2000));
          }
        }
  
        // Alerta quando o processo for concluído
        alert("RESTORED");
      } else {
        console.error("Data is not an array:", dataArray);
      }
    } catch (error) {
      console.error("Error parsing data as JSON:", error);
    }
  };
  
  

  return (
    <div className={`sidebar ${isOpen ? 'open' : ''}`}>
      <button className="close-button" onClick={onClose}>
        <FontAwesomeIcon icon={faTimes} />
      </button>
      {driverInfo && driverInfo.length > 0 && (
        <div>
          <h2>DRIVER BACKUPS</h2>
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Driver Name</th>
                <th>Company</th>
                <th>Date</th>
                <th>Restore</th>
              </tr>
            </thead>
            <tbody>
              {driverInfo.map((info) => (
                <tr key={info.id}>
                  <td>{info.id}</td>
                  <td>{info.driver}</td>
                  <td>{info.company}</td>
                  <td>{format(new Date(info.date), 'MM/dd/yyyy HH:mm')}</td>
                  <td>
                    <button onClick={() => restoreback(info.data)}>Restore</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default Sidebar;
