import { obterDataFormatada } from './todayDate';
import sendChanges from './sendChanges';
import { convertTimestampToET } from './timezones';
import chekIfisAvaible from './checkIfIsAvaible';
import {goToToday} from '../components/tableChat';
import { da, el } from 'date-fns/locale';

export async function untilToday() {
  
  let i = 0;
  const requestOptions = {
    method: 'GET',
    headers: { 
      "accept": "application/json",
      "accept-language": "pt-BR,pt;q=0.9,en-US;q=0.8,en;q=0.7",
      "authorization": localStorage.getItem('token'),
      "content-type": "application/json",
      "if-none-match": "W/\"1186f-3c2PqL1eocqag01MrbGuekXTmK8\"",
      "sec-ch-ua": "\"Not_A Brand\";v=\"99\", \"Google Chrome\";v=\"109\", \"Chromium\";v=\"109\"",
      "sec-ch-ua-mobile": "?0",
      "sec-ch-ua-platform": "\"Windows\"",
      "sec-fetch-dest": "empty",
      "sec-fetch-mode": "cors",
      "sec-fetch-site": "cross-site" 
    }
  };
  const firstDate = localStorage.getItem('firstDate');
  console.log(firstDate);
  const lastDate = obterDataFormatada();
  const userId = localStorage.getItem('userId');
  const url = `https://backend.apexhos.com/hos_events?userId=${userId}&eventTime.logDate.date%5B%24lte%5D=${lastDate}&eventTime.logDate.date%5B%24gte%5D=${firstDate}`;
  console.log(url);
  try {
    
    const response = await fetch(url, requestOptions);
    const result = await response.json();
    
    result.data.sort((a, b) => {
      const aDate = new Date(a.eventTime.timestamp);
      const bDate = new Date(b.eventTime.timestamp);
      return aDate - bDate;
    });
    const data1 = result.data;
    console.log(data1);
    console.log("length"+ data1.length);
    


    const confirmed = true;
    let notFuture = true;
    localStorage.setItem('hosEventsLog',  JSON.stringify(data1))
    // saveDbInfo(JSON.stringify(data));
    data1.map((hosEventElement) => {
      if (document.getElementById("timeCondition").value === 'foward') {
        const now = new Date().getTime();
        const time = hosEventElement.eventTime.timestamp;
        const hoursToms = document.getElementById("hoursToChange").value * 3600;
        const newTime = time / 1000;
        const newTimeStamp = (newTime + hoursToms) * 1000;
        console.log('agora' , now);
        console.log('mudado ' , newTimeStamp);
        console.log(newTimeStamp > now);
        
        if (newTimeStamp > now && false) {
          console.log('not allowed time travel yet');
          alert(`schedule event ${hosEventElement.id} is not allowed`)
          notFuture = false;
          return "";
        }
      }
      return "";
    });
    if (confirmed && notFuture) {
      console.log('lenght '+ data1.length);
        for (let i = 0; i < data1.length; i++) {
          let hosEventElement = data1[i];
      const hosEvent = hosEventElement._id;
      const time = hosEventElement.eventTime.timestamp;
      console.log(hosEvent);
        console.log('tem');
          console.log(hosEventElement);
          if (document.getElementById("timeCondition").value === 'back') {
            const hoursToms = document.getElementById("hoursToChange").value * 3600;
            const newTime = time / 1000;
            const newTimeStamp = (newTime - hoursToms) * 1000;
            hosEventElement.eventTime.timestamp = newTimeStamp;


          }
          if (document.getElementById("timeCondition").value === 'foward') {
            const hoursToms = document.getElementById("hoursToChange").value * 3600;
            const newTime = time / 1000;
            const newTimeStamp = (newTime + hoursToms) * 1000;
            hosEventElement.eventTime.timestamp = newTimeStamp;
          }

          const dateFormated = convertTimestampToET(hosEventElement.eventTime.timestamp, localStorage.getItem('timezone') );
        let dateObj = new Date(dateFormated); // convert date string to a Date object

        let year = dateObj.getFullYear();
        let month = String(dateObj.getMonth() + 1).padStart(2, '0');
        let day = String(dateObj.getDate()).padStart(2, '0');
        let transformedDate = `${year}/${month}/${day}`;
        hosEventElement.eventTime.logDate.date = transformedDate;
        console.log(`before change ${time} : ${transformedDate} after change ${hosEventElement.eventTime.timestamp}`);
        console.log(hosEventElement);
        if (i === data1.length - 1) {
          console.log('last event' + hosEventElement.eventCode.id);
          if( hosEventElement.eventCode.id === 'DS_ON' || hosEventElement.eventCode.id === 'DS_D'){
            console.log('last event is on or driving');
            console.log(hosEventElement);
            alert('last event is on or driving');
            // sendChanges(hosEvent, hosEventElement, 'PUT')

            let notLast = false;
          }else{
            let notLast = true;
            sendChanges(hosEvent, hosEventElement, 'PUT')
          }
        }
        else{
          sendChanges(hosEvent, hosEventElement, 'PUT')
        }
        
        
        console.log(i);
        console.log(hosEventElement.id)
        if ((i + 1) % 75 === 0) {
          await new Promise(resolve => setTimeout(resolve, 2000));
        }
      }
      alert('done');
    }
    else{
      console.log('no confirmed');
    }




  } catch (error) {
    console.log(error);
  }

}