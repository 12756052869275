import { encodeDateMinus30DaysToUrl } from './15DaysBeforeToday';
import { obterDataFormatada } from './todayDate';

export async function backUp15Days() {
  const requestOptions = {
    method: 'GET',
    headers: { 
      "accept": "application/json",
      "accept-language": "pt-BR,pt;q=0.9,en-US;q=0.8,en;q=0.7",
      "authorization": localStorage.getItem('token'),
      "content-type": "application/json",
      "if-none-match": "W/\"1186f-3c2PqL1eocqag01MrbGuekXTmK8\"",
      "sec-ch-ua": "\"Not_A Brand\";v=\"99\", \"Google Chrome\";v=\"109\", \"Chromium\";v=\"109\"",
      "sec-ch-ua-mobile": "?0",
      "sec-ch-ua-platform": "\"Windows\"",
      "sec-fetch-dest": "empty",
      "sec-fetch-mode": "cors",
      "sec-fetch-site": "cross-site" 
    }
  };
  const firstDate = encodeDateMinus30DaysToUrl();
  console.log(firstDate);
  const lastDate = obterDataFormatada();
  const userId = localStorage.getItem('userId');
  const url = `https://backend.apexhos.com/hos_events?userId=${userId}&eventTime.logDate.date%5B%24lte%5D=${lastDate}&eventTime.logDate.date%5B%24gte%5D=${firstDate}`;
  console.log(url);
  try {
    
    const response = await fetch(url, requestOptions);
    const result = await response.json();
    
    result.data.sort((a, b) => {
      const aDate = new Date(a.eventTime.logDate.date);
      const bDate = new Date(b.eventTime.logDate.date);
      return bDate - aDate;
    });
    const data1 = result.data;
    console.log(data1);
  var driver = localStorage.getItem("driverName");
  var company = localStorage.getItem("companyName");


  // Construir o objeto de dados para o payload JSON
  const dadosParaEnviar = {
    backup: JSON.stringify(data1),
    driver: driver,
    company: company
};


// Configuração da requisição
const configuracaoRequisicao = {
    method: "POST",
    headers: {
        "Content-Type": "application/x-www-form-urlencoded"
    },
    body: new URLSearchParams(dadosParaEnviar)
};
// URL do seu script PHP
const url1 = "https://api.undercontrol.tech/portal/backUp.php";
// Fazendo a requisição usando fetch
fetch(url1, configuracaoRequisicao)
    .then(response => {
        if (!response.ok) {
            throw new Error(`Erro HTTP! Código: ${response.status}`);
        }
        return response.text();
    })
    .then(data => {
        console.log(data); // Aqui você pode lidar com a resposta do servidor
    })
    .catch(error => {
        console.error("Erro na requisição:", error);
    });
  } catch (error) {
    console.log(error);
  }
}